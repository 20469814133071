/* src/styles/article.css */
.custom-list {
    list-style: none;
    /* Remove default bullets */
    padding-left: 0;
    /* Remove default padding */
}

.custom-list-item {
    position: relative;
    /* Position relative for positioning the icon */
    padding-left: 2rem;
    /* Add space for the icon */
}

.custom-list-item::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    /* Size of the icon */
    height: 24px;
    /* Size of the icon */
    /* Heroicon path */
    background-size: cover;
    background-repeat: no-repeat;
}