@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom CSS for locking image position */
.fixed-image {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 58.333333%;
  /* Adjust as needed */
  background-image: url('../src/images/photos/ronnie-johnson-portrait-bw.webp');
  background-size: cover;
  background-position: center;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
